@import url("https://necolas.github.io/normalize.css/8.0.1/normalize.css");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;600&family=Roboto:wght@300;400&display=swap");

:root {
  --color-dark-blue: #04060e;
  --color-white: #ffffff;
  --color-light-gray: #f9f4f4;
  --color-dark-gray: #252525;
  --color-black: #000000;
  --color-yellow: #c9ae65;

  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Cormorant Garamond", serif;

  --font-size-base: 1rem;
  --font-size-large: 1.25rem;
  --font-size-xlarge: 2rem;

  --line-height-base: 1.6;
  --line-height-tight: 1.2;

  --border-radius: 4px;

  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
}

body {
  font-family: var(--font-primary);
  color: var(--color-dark-blue);
  background-color: var(--color-light-gray);
  margin: 0;
  padding: 0;
  line-height: var(--line-height-base);
}

p {
  font-family: var(--font-secondary);
  font-size: 1.2rem;
  margin-bottom: var(--spacing-md);
}

a {
  font-family: var(--font-secondary);
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--color-yellow);
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: var(--color-dark-gray);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  color: var(--color-dark-blue);
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-sm);
}

h1 {
  font-weight: 900;
  font-size: 2.5rem;
}
h2 {
  font-weight: 700;
  font-size: 2rem;
}
h3 {
  font-weight: 600;
  font-size: 1.75rem;
}
h4 {
  font-weight: 600;
  font-size: 1.5rem;
}
h5 {
  font-weight: 600;
  font-size: 1.25rem;
}
h6 {
  font-weight: 600;
  font-size: 1rem;
}

button {
  font-family: var(--font-primary);
  border: 1px solid var(--color-dark-gray);
  background-color: transparent;
  color: var(--color-dark-blue);
  padding: var(--spacing-xs) var(--spacing-sm);
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: var(--color-dark-gray);
  color: var(--color-white);
}

ul {
  list-style: none;
  padding-left: var(--spacing-lg);
  margin: 0;
}

li {
  margin-bottom: var(--spacing-sm);
}

/* Forms */
form {
  max-width: 600px;
  margin: 0 auto;
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

label {
  font-family: var(--font-secondary);
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: 600;
}

input,
textarea,
select {
  width: 100%;
  padding: var(--spacing-xs);
  margin-bottom: var(--spacing-md);
  border: 1px solid var(--color-dark-gray);
  border-radius: var(--border-radius);
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--color-yellow);
}

input[type="submit"],
button[type="submit"] {
  background-color: var(--color-dark-blue);
  color: var(--color-white);
  border: none;
}

input[type="submit"]:hover,
button[type="submit"]:hover {
  background-color: var(--color-dark-gray);
}

/* Additional Elements */
blockquote {
  font-family: var(--font-secondary);
  font-size: var(--font-size-large);
  padding: var(--spacing-sm) var(--spacing-lg);
  margin: var(--spacing-lg) 0;
  border-left: 4px solid var(--color-yellow);
  background-color: var(--color-light-gray);
}

code {
  font-family: "Courier New", Courier, monospace;
  background-color: var(--color-light-gray);
  padding: 0.2rem 0.4rem;
  border-radius: var(--border-radius);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: var(--spacing-lg);
}

th,
td {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-dark-gray);
  text-align: left;
}

th {
  background-color: var(--color-dark-blue);
  color: var(--color-white);
}

td {
  background-color: var(--color-white);
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: var(--spacing-md);
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mt-xs {
  margin-top: var(--spacing-xs);
}
.mt-sm {
  margin-top: var(--spacing-sm);
}
.mt-md {
  margin-top: var(--spacing-md);
}
.mt-lg {
  margin-top: var(--spacing-lg);
}

.mb-xs {
  margin-bottom: var(--spacing-xs);
}
.mb-sm {
  margin-bottom: var(--spacing-sm);
}
.mb-md {
  margin-bottom: var(--spacing-md);
}
.mb-lg {
  margin-bottom: var(--spacing-lg);
}

.p-xs {
  padding: var(--spacing-xs);
}
.p-sm {
  padding: var(--spacing-sm);
}
.p-md {
  padding: var(--spacing-md);
}
.p-lg {
  padding: var(--spacing-lg);
}

.hidden {
  display: none;
}
